<template>
  <v-app>
    <v-card width="400px" class="mt-5 mx-auto">
      <div class="text-center mt-5">
        <img class="logo" alt="C-Learning Logo" src="https://www.c-learning.com/wp-content/uploads/2022/01/c-learning-logo-retina.png">
        <h1>{{ $t('login') }}</h1>
      </div>
      <v-card-text>
        <v-form value>
          <v-text-field
              :label="$t('username')"
              prepend-icon="mdi-account-circle"
              v-model="email"
          />
          <v-text-field
              :label="$t('password')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password"
              @keydown.enter.prevent="doLogin"
          />
          <a href="https://kurse.c-learning.com/app/forgot-password">{{ $t('Reset password') }}</a>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="primary"
            class="mx-auto px-4"
            @click="doLogin"
        >
          {{ $t('login') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="loginFail"
        color="error"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>
<script>
import APIService from "@/services/APIService";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: '',
      sendPostMessage: true
    }
  },
  methods: {
    ...mapMutations(['updateUser']),
    ...mapActions(['logout','login','fetchManagedUsers']),
    doLogin() {
      APIService.login(this.email, this.password).then((userData) => {
        const fm = userData.user.hasFrontendManagedLicences ? "&fm=1": "&fm=0";
        this.updateUser(userData)
        const { client_id, scope, redirect_uri, state} = this.$route.query
        APIService.getAuthCode(client_id, redirect_uri, scope).then((code) => {
          if(this.sendPostMessage && this.IsiFrame){
            parent.postMessage(redirect_uri+"?code="+code+(state ? "&state="+state : ""),"*");
          }else{
            window.location.href=  `${redirect_uri}?code=${code}${state ? `&state=${state}` : ''}${fm}`
          }
        });
      }).catch(error => {
        console.log("Login failed", error)
        this.logout()
        if (error.response) {
          if(error.response.status === 401){
            this.loginFailMessage = this.$t('Wrong username or password.')
          }else{
            this.loginFailMessage = this.$t('Network error')
          }
        }
        this.loginFail = true
        this.password = ''
      })
    }
  },
  computed: {
    ...mapGetters(['accessToken'])
  },
  beforeRouteEnter(to, from, next) {
    const { response_type, client_id, scope, redirect_uri, state} = to.query
    if(response_type !== 'code' || !client_id || !redirect_uri || !scope || !scope.includes('openid')) {
      next({name: 'error', params: { message: "Bad request" }})
      return
    }
    // store.state.user.tokens.access.token = undefined
    // store.state.user.tokens.refresh.token = 'blablainvalid'

    APIService.getAuthCode(client_id, redirect_uri, scope).then( code => {
      // TODO Prevent redirect on refresh token login redirects for SSO
      window.location.href= `${redirect_uri}?code=${code}${state ? `&state=${state}` : ''}`
    }).catch(error => {
      if(error.response.status === 401) {
        next()
        return
      }
      next({name: 'error', params: { message: error.response.data.message }})
    })
  },
  mounted() {
    this.$i18n.locale = "en";
    //console.log("locale="+this.$route.query.locale);
    if(this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  max-height: 30px;
}
</style>
