import { render, staticRenderFns } from "./UserCourses.vue?vue&type=template&id=596ff7a6&scoped=true&"
import script from "./UserCourses.vue?vue&type=script&lang=js&"
export * from "./UserCourses.vue?vue&type=script&lang=js&"
import style0 from "./UserCourses.vue?vue&type=style&index=0&id=596ff7a6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596ff7a6",
  null
  
)

export default component.exports