<template>
  <div style="color: #03164e;" class="locale-switcher">
    <v-icon style="margin-right: 5px;color: #03164e;">mdi-earth</v-icon>
    <select v-model="$i18n.locale">
      <option value="en">English</option>
      <option value="cn">中国人</option>
      <option value="de">Deutsch</option>
      <option value="es">Español</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher"
}
</script>

<style scoped>

</style>
