import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import APIService from "@/services/APIService";
import localforage from 'localforage';

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user'],   // Hier spezifizieren wir, welche Pfad in localStorage gespeichert werden sollen
    }),
      createPersistedState(
      {
        storage: {
          getItem: key => localforage.getItem(key),
          setItem: (key, value) => localforage.setItem(key, value),
          removeItem: key => localforage.removeItem(key),
        },
      }
  )],
  modules: {
  },
  state: {
    user: null,
    users: [],
    startDate: null,
    endDate: null,
    managedUsers: [],
    options: {
      itemsPerPage: 15
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user !== null
    },
    hasRole: (state) => (role) => {
      return state?.user?.role === role
    },
    accessToken: (state) => {
      return state?.user?.tokens?.access?.token
    },
    refreshToken: (state) => {
      return state?.user?.tokens?.refresh?.token
    }
  },
  mutations: {
    setDates(state, dates) {
      state.startDate = dates.startDate;
      state.endDate = dates.endDate;
    },
    updateUser(state, data) {
      const {user, tokens} = data
      state.user = user
      state.user.tokens = tokens
    },
    updateTokens(state, tokens) {
      state.user = { ...state.user, tokens }
    },
    async logout(state) {
      state.user = null
    },
    updateUsers(state,users) {
      state.users = users
    },
    updateManagers(state,managers) {
      state.user.managers = managers
    },
    updateManagedUsers(state,managedUsers) {
      state.managedUsers = managedUsers
    },
    updateItemsPerPage(state, itemsPerPage) {
      state.options = { ...state.options, itemsPerPage }
    },
    resetManagedUsers (state) {
      if(state.managedUsers)
        state.managedUsers = [];
    }
  },
  actions: {
    login({commit}, {email, password}){
      return APIService.login(email, password).then((user) => {
        commit('updateUser', user)
      })
    },
    async logout({commit, getters}){
      if(getters.isLoggedIn) {
        await APIService.logout(store.getters.refreshToken)
        commit('logout')
      }
    },
    refreshToken({commit,state}) {
      const token = state?.user?.tokens?.refresh?.token ?? 'invalid'
      return APIService.refreshToken(token).then(tokens => {
        commit('updateTokens', tokens)
        return tokens
      })
    },
    fetchUsers({commit}) {
      return APIService.getUsers().then((users) => {
        //console.log("users", users)
        for(let i=0; i<users.length; i++){
          users[i].completeName = users[i].firstName + " " + users[i].lastName;
        }
        commit('updateUsers', users)
      })
    },
    fetchManagedUsers({commit, state}) {
      return APIService.getManagedUsers(state.user.id).then((users) => {
        commit('updateManagedUsers', users)
      })
    },
  }
})

export default store
