import axios from 'axios'
import router from '@/router'
import store from '@/store'

const config = {
    baseURL: `${process.env.VUE_APP_ROOT_API}`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
}
let originalUrl = null

const apiClient = axios.create(config)

function authHeaderInterceptor(config) {
    const token = store.state?.user?.tokens?.access?.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

function accessTokenRefreshInterceptor(error) {
    const status = error.response.status
    const config = error.config
    if(config.url !== '/auth/refresh-tokens') {
        originalUrl = config.url
    }
    if (status !== 401) {
        return Promise.reject(error);
    }
    if (config.url === '/auth/login'
        || config.url === '/sso/authTokens') {
        return Promise.reject(error);
    }

    // TODO check if user is disabled
    if (config.url === '/auth/refresh-tokens') {
        store.commit('logout')
        if(originalUrl !== '/sso/authCode') {
            router.push({ name: 'adminLogin'});
        }
        return Promise.reject(error);
    }
    const refreshToken = store.getters.refreshToken ? store.getters.refreshToken : 'invalid_token'
    if(!config._retry) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            const tokens = response?.data
            store.commit('updateTokens', tokens)
            config.headers['Authorization'] = `Bearer ${tokens?.access?.token}`;
            config._retry = true
            return apiClient.request(config)
        })
    }
}

apiClient.interceptors.request.use((config) => {
    return authHeaderInterceptor(config)
})
apiClient.interceptors.response.use(response => {
    return response
}, error => {
    return accessTokenRefreshInterceptor(error)
})
export default {
    login(email, password) {
        return apiClient.post('/auth/login', { email, password }).then(response => {
                return response.data
            }
        )
    },
    logout(refreshToken) {
        return apiClient.post('/auth/logout', { refreshToken }).catch((error) => {
            if (error.response && error.response.status !== 404) {
                throw error;
            }
        })
    },
    refreshToken(refreshToken) {
        return apiClient.post('/auth/refresh-tokens', { refreshToken }).then(response => {
            return response?.data
        })
    },
    getBadge(badgeId) {
        return apiClient.get( `/badges/${badgeId}`).then(result => {
            return result.data
        })
    },
    getBadges() {
        return apiClient.get( "/badges").then(result => {
            return result.data.results
        })
    },
    saveBadge(badge) {
        const _badge = {...badge}
        _badge.image =   badge.image?.id
        delete _badge.author
        delete _badge.lastUpdatedBy
        if(!badge.id) {
            return apiClient.post( "/badges", _badge).then(result => {
                return result.data.results
            })
        } else {
            delete _badge.id
            delete _badge.createdAt
            delete _badge.updatedAt
            return apiClient.patch( `/badges/${badge.id}`, _badge).then(result => {
                return result.data.results
            })
        }
    },
    deleteBadge(badge) {
        return apiClient.delete( `/badges/${badge.id}`).then()
    },
    getUsers() {
        return apiClient.get("/users").then(result => {
            return result.data.results
        })
    },
    getUser(id) {
        return apiClient.get("/users/"+id).then(result => {
            return result.data
        })
    },
    getPartners() {
        return apiClient.get("/users?role=partner").then(result => {
            return result.data.results
        })
    },
    getTeamMembers(userId) {
        return apiClient.get(`/users/${userId}/teamMembers`).then(result => {
            return result.data.results
        })
    },
    getManagedUsers(userId) {
        return apiClient.get(`/users/${userId}/managedUsers`).then(result => {
            return result.data.results
        })
    },
    addTeamMember(userId, member) {
        return apiClient.post(`/users/${userId}/teamMembers`, member).then(result => {
            console.log("Employees:", result.data)
        })
    },
    removeTeamMember(userId, memberId) {
        return apiClient.delete(`/users/${userId}/teamMembers/${memberId}`).then(result => result.data.results)
    },
    isEmailTaken(email) {
        return apiClient.get(`/users?email=${email}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    isProductNameTaken(productName) {
        return apiClient.get(`/products?name=${productName}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    saveUser(user) {
        const _user = {...user}
        _user.password = _user.password === '' ? undefined : _user.password
        return apiClient.post("/users", _user).then(result => {
            return result.data
        })
    },
    updateUser(user) {
        const userId = user.id
        const _user = {...user}
        delete _user.id
        delete _user.assignments
        delete _user.isRegistered
        delete _user.teamMemberships
        delete _user.creator
        delete _user.teamMembers
        delete _user.completeName
        return apiClient.patch(`/users/${userId}`, _user).then(result => {
            return result.data
        })
    },
    deleteUser(user) {
        return apiClient.delete(`/users/${user.id}`).then(result => {
            return result.data
        })
    },
    getGroup(group) {
        return apiClient.get(`/groups/${group.id}`).then(result => {
            return result.data.results
        })
    },
    getUserGroups(userId) {
        return apiClient.get(`/users/${userId}/groups`).then(result => {
            return result.data.results
        })
    },
    saveGroup(group) {
        return apiClient.post("/groups", {...group}).then(result => {
            return result.data
        })
    },
    updateGroup(group) {
        const groupId = group.id
        const _group = {...group}
        delete _group.id
        delete _group.owner

        return apiClient.patch(`/groups/${groupId}`, _group).then(result => {
            return result.data
        })
    },
    deleteGroup(group) {
        return apiClient.delete(`/groups/${group}`).then(result => {
            return result.data
        })
    },
    getProducts() {
        return apiClient.get( "/products").then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting products", error)
            return Promise.reject(error)
        })
    },
    getProduct(product) {
        return apiClient.get( `/products/${product.id}`).then(result => {
            return result.data
        }).catch(error => {
            console.log("Error getting product", error)
            return Promise.reject(error)
        })
    },
    getLicences() {
        return apiClient.get( "/licences").then(result => {
            //console.log("Licences:", result.data)
            return result.data.results
        }).catch(error => {
            console.log("Error getting licences", error)
            return Promise.reject(error)
        })
    },
    getUserLicences(userId) {
        return apiClient.get( `users/${userId}/licences`).then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting licences", error)
            return Promise.reject(error)
        })
    },
    getUserAssignedLicences(userId) {
        return apiClient.get( `users/${userId}/assignedLicences`).then(result => {
            return result.data.results
        }).catch(error => {
            console.log("Error getting assigned licences", error)
            return Promise.reject(error)
        })
    },
    saveProduct(product) {
        if(!product.id) {
            return apiClient.post("/products", product).then(result => {
                return result.data
            })
        } else {
            const _product = {...product}
            const productId = product.id
            delete _product.id
            delete _product.createdAt
            delete _product.updatedAt
            delete _product.lastUpdatedBy
            delete _product.author
            return apiClient.patch(`/products/${productId}`, _product).then(result => {
                return result.data
            })
        }
    },
    saveLicence(licence) {
        if(!licence.id){
            return apiClient.post("/licences", licence).then(result => {
                return result.data
            })
        } else {
            const {startDate, expirationDate, quantityTotal, orderNumber, comment, partner, isFrontendManageable} =  licence
            return apiClient.patch(`/licences/${ licence.id }`, {
                startDate, expirationDate, quantityTotal, orderNumber, comment, partner, isFrontendManageable
            }).then(result => {
                return result.data
            })
        }
    },
    updateAssignments(licenceId, assignments) {
        return apiClient.put(`/licences/${licenceId}/assignments`, {assignments} ).then(result => {
            console.log(result)
        })
    },
    getAssignments(licenceId) {
        return apiClient.get(`/licences/${licenceId}/assignments`).then(result => result.data)
    },
    getAssignedCourseUsers(courseId) {
        return apiClient.get(`courses/${courseId}/assignedUsers`).then(result => result.data.results)
    },
    deleteLicence(licence) {
        return apiClient.delete(`/licences/${licence.id}`)
    },
    deleteProduct(product) {
        return apiClient.delete(`/products/${product.id}`)
    },
    getAuthCode(clientId, redirectUri, scope) {
        return apiClient.post(`/sso/authCode`, {clientId, redirectUri, scope}).then(result => {
            return result.data
        })
    },
    getCourses(tags = undefined) {
        return apiClient.get( "/courses" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
    getUserCourses(userId) {
        return apiClient.get( `/users/${userId}/courses`).then(result => {
            return result.data
        })
    },
    getCoursesForLicence(licence) {
        return apiClient.get( `/products/${licence.product.id}/courses?populateCourses=true`).then(result => {
            return result.data.courses
        })
    },

    deleteCourse(course) {
        return apiClient.delete(`/courses/${course.id}`)
    },
    saveCourse(course) {
        const _course = { ...course }
        _course.image = _course.image ? _course.image.id : undefined
        _course.events.forEach(event => delete event['id'])
        if(!course.id) {
            return apiClient.post("/courses", _course).then(result => {
                return result.data
            })
        } else {
            const courseId = course.id
            delete _course.id
            delete _course.createdAt
            delete _course.updatedAt
            delete _course.lastUpdatedBy
            delete _course.author
            return apiClient.patch(`/courses/${courseId}`, _course).then(result => {
                return result.data
            })
        }
    },
    getCourse(course, deep = false) {
        return apiClient.get(`/courses/${course.id}?deep=${deep ? "true": "false"}`).then(result => {
            return result.data
        })
    },
    isCourseTitleTaken(courseTitle) {
        return apiClient.get(`/courses?name=${courseTitle}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    upload(file, onUploadProgress, extractZip, description = "") {
        let formData = new FormData();

        formData.append("file", file);
        formData.append("description", description)
        formData.append("unzipContent", extractZip.toString())

        return apiClient.post("/content/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress,
            timeout: 0
        });
    },
    getChapters(tags = undefined) {
        return apiClient.get( "/chapters" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
    getTags() {
        return apiClient.get( "/tags").then(result => result.data.results).then(tags => {
            //console.log("Tags:", tags.data)
            return tags
        })
    },
    deleteChapter(chapter) {
        return apiClient.delete(`/chapters/${chapter.id}`)
    },
    saveChapter(chapter) {
        const _chapter = { ...chapter }
        if(chapter.content) {
            _chapter.content=_chapter.content.map(content=> ({file: content.file.id, path: content.path}))
        }
        _chapter.image = _chapter.image ? _chapter.image.id : undefined
        if(!chapter.id) {
            return apiClient.post("/chapters", _chapter).then(result => {
                return result.data
            })
        } else {
            const chapterId = chapter.id
            delete _chapter.id
            delete _chapter.createdAt
            delete _chapter.updatedAt
            delete _chapter.assignedTo
            delete _chapter.lastUpdatedBy
            delete _chapter.author
            return apiClient.patch(`/chapters/${chapterId}`, _chapter).then(result => {
                return result.data
            })
        }
    },
    getChapter(chapter) {
        return apiClient.get(`/chapters/${chapter.id}`).then(result => {
            return result.data
        })
    },
    deleteLesson(lesson) {
        return apiClient.delete(`/lessons/${lesson.id}`)
    },
    getLesson(lesson) {
        return apiClient.get(`/lessons/${lesson.id}`).then(result => {
            return result.data
        })
    },
    getLessons(tags = undefined) {
        return apiClient.get( "/lessons" + (tags ? "?tags=" + tags  : "")).then(result => {
            return result.data.results
        })
    },
    saveLesson(lesson) {
        const _lesson = { ...lesson }
        if(lesson.content) {
            _lesson.content=_lesson.content.map(content=> ({file: content.file.id, path: content.path}))
        }
        _lesson.image = _lesson.image ? _lesson.image.id : undefined
        if(!lesson.id) {
            return apiClient.post("/lessons", _lesson).then(result => {
                return result.data
            })
        } else {
            const lessonId = lesson.id
            delete _lesson.id
            delete _lesson.createdAt
            delete _lesson.updatedAt
            delete _lesson.assignedTo
            delete _lesson.lastUpdatedBy
            delete _lesson.author
            return apiClient.patch(`/lessons/${lessonId}`, _lesson).then(result => {
                return result.data
            })
        }
    },
    isChapterTitleTaken(chapterTitle) {
        return apiClient.get(`/chapters?name=${chapterTitle}`).then((result) => {
            return result.data.totalResults > 0
        })
    },
    getFiles() {
        return apiClient.get("/files");
    },
    /* Events
    *  Event Types
    *  curl -X GET -H 'Content-type:application/json' -H 'Authorization: Bearer xxx' 'https://api.c-learning.de:444/v1/statistics/logs?fromDate=2022-01-01&toDate=2023-12-31&userId=62f9f8cb11b882002710eecc'
    *  Man kann jetzt 'type', 'userId', 'courseId', 'chapterId', 'lessonId' sowie fromDate und toDate angeben.
    *
    * */
    sendEvent(type, value = "", courseId = "", chapterId = "", lessonId = "", licenseId = ""){


        let data = {
            "type": type
        };
        if(value !== ""){
            data.value = value;
        }
        if(courseId !== ""){
            data.courseId = courseId;
        }
        if(chapterId !== ""){
            data.chapterId = chapterId
        }
        if(lessonId !== ""){
            data.lessonId = lessonId;
        }
        if(licenseId !== ""){
            data.licenceId = licenseId;
        }

        return apiClient.post("/statistics/logs", data).then(result => {
            //console.log(result);
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getEvents(type = "", userId = "", courseId = "", chapterId = "", lessonId = "", value = "", fromDate = null, toDate = null, licenseId = ""){

        let addParams = "";
        if(type !== ""){
            addParams += "&type="+type;
        }
        if(value !== ""){
            addParams += "&value="+value;
        }
        if(courseId !== ""){
            addParams += "&courseId="+courseId;
        }
        if(chapterId !== ""){
            addParams += "&chapterId="+chapterId
        }
        if(lessonId !== ""){
            addParams += "&lessonId="+lessonId;
        }
        if(licenseId !== ""){
            addParams += "&licenceId="+licenseId;
        }
        if(userId !== ""){
            addParams += "&userId="+userId;
        }
        if(fromDate){
            addParams += "&fromDate="+fromDate.toISOString().slice(0, 10);
        }
        if(toDate){
            addParams += "&toDate="+toDate.toISOString().slice(0, 10);
        }


        return apiClient.get(`/statistics/logs/?limit=10000000&sortBy=date:desc`+addParams).then(result => {
            return result.data.results;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getUserEvents(userId, type = ""){
        let addParams = "";
        if(type != ""){
            addParams += "&type="+type;
        }
        return apiClient.get(`/statistics/logs/?userId=${userId}&sortBy=date:desc`+addParams).then(result => {
            return result.data;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getStudyProgress(courseId, fromDate, toDate = new Date()){
        let addParams = "?sortBy=completionDate:desc";
        if(fromDate){
            addParams += "&fromDate="+fromDate.toISOString().slice(0, 10);
        }
        if(toDate){
            addParams += "&toDate="+toDate.toISOString().slice(0, 10);
        }
        return apiClient.get("/courses/"+courseId+"/studyProgress"+addParams).then(result => {
            return result.data.results;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    getStudyProgressUser(userId, fromDate, toDate = new Date()){
        // eslint-disable-next-line no-unused-vars
        let addParams = "?sortBy=completionDate:desc";
        if(fromDate){
            addParams += "&fromDate="+fromDate.toISOString().slice(0, 10);
        }
        if(toDate){
            addParams += "&toDate="+toDate.toISOString().slice(0, 10);
        }
        return apiClient.get("/users/"+userId+"/studyProgress").then(result => {
            let sortedResult = result.data.results.sort((a,b) => {
                let dateA = a.completionDate ? new Date(a.completionDate) : new Date(0); // epoch if no date
                let dateB = b.completionDate ? new Date(b.completionDate) : new Date(0); // epoch if no date
                return dateB - dateA;
            });
            return sortedResult;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
     importUsers(userId, selectedImportUsers) {
        const importUsers = selectedImportUsers.map((user) => ({...user, invalid: undefined, password: user.password ? user.password: undefined}))
        return apiClient.post(`/users/${userId}/import`, importUsers).then(result => {
            return result.data
        })
    },
    migrateUsers(selectedImportUsers) {
        return apiClient.post("/users/migrate", selectedImportUsers).then(result => {
            return result.data
        })
    },
    //Favorites
    async createFavorite(favorite) {
        return apiClient.post("/favorites", favorite).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async updateFavorite(favorite) {
        return apiClient.put("/favorites/"+favorite.id, favorite).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async deleteFavorite(favorite_id){
        return apiClient.delete("/favorites/"+favorite_id).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getFavorites(list) {
        return apiClient.get('/favorites?list='+list).then(result => {
            return result.data.results
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getFavoritesForUser(user) {
        return apiClient.get('/favorites?user='+user).then(result => {
            return result.data.results
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async createFavoritesLists(list) {
        return apiClient.post("/favoriteslists", list).then(result => {
            return result.data
        }).catch(error => {
            console.log("createFAvoritesLists error: "+error);
            return null;
        });
    },
    async updateFavoritesLists(list) {
        let _list = {...list};
        delete _list.id;
        return apiClient.patch("/favoriteslists/"+list.id, _list).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async deleteFavoritesList(list_id){
        return apiClient.delete("/favoriteslists/"+list_id).then(result => {
            return result.data
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getFavoritesLists() {
        return apiClient.get("/favoritesLists").then(result => {
            return result.data.results;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getFavoritesListsUser() {
        return apiClient.get("/favoritesLists").then(result => {
            let favs = [];
            for(let i=0; i < result.data.results.length; i++){
                if(!result.data.results[i].group || result.data.results[i].group == ""){
                    favs.push(result.data.results[i]);
                }
            }
            return favs;
        }).catch(error => {
            console.log(error);
            return null;
        });
    },
    async getFavoritesListsGroup(group) {
        return apiClient.get('/favoriteslists?group='+group).then(async result => {
            return result.data.results
        }).catch(error => {
            console.log(error);
            return false;
        });
    },

}
