<template>
  <v-app>
    <v-navigation-drawer
        :value="isLoggedIn"
        clipped
        app
        permanent
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="user && user.avatarUrll">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user && `${user.firstName} ${user.lastName}`}}</v-list-item-title>
            <v-list-item-subtitle>{{ user && user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider v-if="showTeamMenuItems"></v-divider>
      <v-list>
        <template v-if="showTeamMenuItems && isLoggedIn && (user.role ==='user' || user.role ==='partner')">
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/employees/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('My Team') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/groups/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('My Groups', 2) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.hasTeamLicences" :to="`/admin/licenceAssignments/${user.id}`">
            <v-list-item-icon>
              <v-icon>mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('My Licenses') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.isCourseManager" :to="`/admin/courses`">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Manage Courses') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/admin/settings">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Settings') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
        <template v-if="isLoggedIn && user.role !=='user'">
          <template v-for="item in userMenuEntries">

            <v-list-item v-if="!item.items" :key="item.link" :to="item.link">
              <v-list-item-icon>
                <v-icon v-text="item.icon"/>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-else
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.icon"
                no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :to="child.link"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </template>
        <template v-if="managedUsers && managedUsers.length > 0">
          <v-divider/>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{ $t("Managed by me") }}:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              v-for="user in managedUsers"
              :key="user.id"
              sub-group
              v-model="isSubMenuOpen"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{getTitle(user)}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="({title, icon, link}, i) in management"
                :key="i"
                link
                :to="`${link}/${user.id}`"
            >
              <v-list-item-title v-text="title"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <v-divider/>
        <v-list-item @click="doLogout">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Logout')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        clipped-left
        app
        color="white"
        dark
        dense
        height="65"
    >
      <div class="d-flex align-center pl-2" >
       <img style="max-height: 30px;" src="https://www.c-learning.com/wp-content/uploads/2022/01/c-learning-logo-retina.png">
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex align-center locale-switcher-main" style="background-color: transparent; height: 100%; padding: 10px;left: 0px;">
        <LocaleSwitcher/>
      </div>
    </v-app-bar>

    <v-main>
      <v-progress-linear indeterminate :active="true" :color="progressActive ? 'red' : 'white'"></v-progress-linear>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import store from "@/store";
import LocaleSwitcher from "@/components/LocaleSwitcher"
import APIService from "@/services/APIService";

export default {
  name: "AdminLayout",
  components: {
    LocaleSwitcher
  },
  data () {
    return {
      isSubMenuOpen: false,
      instances: null,
      showTeamMenuItems: true,
      progressActive: false,
      management: [
        {title: this.$tc('Team'), icon: 'mdi-account-group', link: '/admin/employees'},
        {title: this.$tc('Group',2), icon: 'mdi-account-multiple', link: '/admin/groups'},
        {title: this.$t('Licenses'), icon: 'mdi-certificate', link: '/admin/licenceAssignments'},
      ],
    }
  },
  async mounted() {
    if (!this.user) {
      this.$store.commit('resetManagedUsers');
    } else {
      this.$store.dispatch('fetchManagedUsers')
          .then();
    }

    if(this.user != null){
      this.checkLicenses();
    }

    if(this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
    }
  },
  watch: {
    user(newValue) {
      if (newValue !== null) {
        this.checkLicenses();
        if (!this.user) {
          this.$store.commit('resetManagedUsers');
        } else {
          this.$store.dispatch('fetchManagedUsers')
              .then();
        }
      }
    }
  },
  methods: {
    async checkLicenses(){
      console.log(this.user);
      this.licences = await APIService.getUserLicences(this.user.id)
      console.log("num licenses: ");
      console.log(this.licences);
      if(this.licences.length > 0 && this.licences[0].quantityTotal > 0 && this.licences[0].isFrontendManageable){
        this.user.hasTeamLicences = true;
      }
      this.showTeamMenuItems = !(this.licences.length < 1 && this.user.role === 'user');
      if (!this.showTeamMenuItems) {
        // navigate to /employees route
        this.isSubMenuOpen = true;
        console.log("show other route");
        await this.$router.push("/admin/empty");
      }
    },
    doLogout() {
      let emailTmp = this.user.email;
      this.$store.dispatch('logout').then(() =>{
        this.$router.push({name: 'adminLogin'})
        window.location.href = "https://kurse.c-learning.com/logout/?email="+emailTmp;
      })
    },
    getTitle(user) {
      return user ?  user.companyName ? user.companyName : `${user.firstName} ${user.lastName}` : ''
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['user', 'managedUsers']),
    userMenuEntries() {
      return this.items.filter(entry => entry.auth && entry.auth.includes(this.user?.role))
    },
    items(){ return [
      {
        title: this.$tc('Customer', 2),
        icon: 'mdi-account',
        link: '/admin/users',
        auth: ['admin', 'sale'],
      },
      {
        title: this.$t('Products'),
        icon: 'mdi-shopping',
        link: '/admin/products',
        auth: ['admin'],
      },
      {
        title: this.$t('Licenses'),
        icon: 'mdi-certificate',
        link: '/admin/licences',
        auth: ['admin', 'sale'],
      },
      {
        title: this.$t('courses'),
        icon: 'mdi-account-group',
        link: '/admin/courses',
        auth: ['admin','author'],
      },
      {
        title: this.$t('Chapters'),
        icon: 'mdi-account-group',
        link: '/admin/chapters',
        auth: ['admin','author'],
      },
      {
        title: this.$t('Lessons'),
        icon: 'mdi-account-group',
        link: '/admin/lessons',
        auth: ['admin','author'],
      },
      {
        title: this.$t('Badges'),
        icon: 'mdi-medal-outline',
        link: '/admin/badges',
        auth: ['admin','author'],
      },
      {
        title: this.$t('Statistics') ,
        icon: 'mdi-chart-timeline',
        auth: ['admin'],
        items: [
          {
            title: this.$t('courses'),
            icon: 'mdi-book',
            link: '/admin/stats-courses',
            auth: ['admin'],
          },
          {
            title: this.$t('Licenses'),
            icon: 'mdi-certificate',
            link: '/admin/stats-licenses',
            auth: ['admin'],
          },
          {
            title: this.$tc('Customer', 2),
            icon: 'mdi-account',
            link: '/admin/stats-users',
            auth: ['admin'],
          }
        ]
      }
    ]},
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,

}
function beforeRouteEnterOrUpdate(to, from, next) {

  parent.postMessage("TEST POST MESSAGE", "*");
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let locale = "de";
  if( urlParams.has('locale') ){
    locale = urlParams.get('locale');
  }
  const user = store.state.user
  if(!user && to.path !=="/admin/login") {
    next('/admin/login')
  } else if(to.path === "/admin") {
    const user = store.state.user
    const hasTeamLicences = user && user.hasTeamLicences;
    const routeName = store.getters.hasRole('admin') ? 'users' :
        store.getters.hasRole('author') ? 'courses' :
            store.getters.hasRole('sale') ? 'users' :
                (store.getters.hasRole('user') && hasTeamLicences) ? 'employees' : 'settings'
    next({ name: routeName, params: {userId: user.id }, query:{locale: locale}})
  }
  else if(user && to.path ==="/admin/login") {
    next( '/admin')
  }
  next()
}
</script>

<style scoped>

</style>
