import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import SSOLogin from "@/views/SSOLogin";
import Error from "@/views/Error";
import AdminLayout from "@/layouts/AdminLayout";
import NotFound from "@/views/NotFound";
import UserCourses from "@/views/UserCourses";
import store from "@/store";
import Empty from "@/views/Empty.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/admin',
    component: Home
  },
  {
    path: '/users/:userId/courses',
    name: 'UserCourses',
    component: UserCourses
  },
  {
    path: '/sso/logout',
    name: 'ssoLogout',
    beforeEnter: async (to) => {
      // reject the navigation
      await store.dispatch('logout')
      if(to.query.redirect_uri) {
        window.location.href= to.query.redirect_uri
      }
      return false
    },
  },
  {
    path: '/sso/login',
    name: 'ssoLogin',
    component: SSOLogin
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminLayout,
    children: [
      {
        path: 'empty',
        name: 'empty',
        component: Empty
      },
      {
        path: 'login',
        name: 'adminLogin',
        component: Login
      },
      {
        path: 'courses',
        name: 'courses',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseList')
      },
      {
        path: 'chapters',
        name: 'chapters',
        component: () => import(/* webpackChunkName: "chapterList" */ '../views/ChapterList')
      },
      {
        path: 'lessons',
        name: 'lessons',
        component: () => import(/* webpackChunkName: "chapterList" */ '../views/LessonList')
      },
      {
        path: 'badges',
        name: 'badges',
        component: () => import(/* webpackChunkName: "badgeList" */ '../views/BadgeList')
      },
      {
        path: 'products',
        name: 'products',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/ProductList')
      },
      {
        path: 'licences',
        name: 'licences',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/LicenceList')
      },
      {
        path: 'licenceAssignments/:userId',
        name: 'licenceAssignments',
        props: true,
        component: () => import(/* webpackChunkName: "groupList" */ '../views/LicenceAssignmentList')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "userList" */ '../views/UserList')
      },
      {
        path: 'employees/:userId',
        name: 'employees',
        props: true,
        component: () => import(/* webpackChunkName: "userList" */ '../views/EmployeeList')
      },
      {
        path: 'groups/:userId',
        name: 'groups',
        props: true,
        component: () => import(/* webpackChunkName: "userList" */ '../views/GroupList')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "userList" */ '../views/Settings')
      },
      {
          path: 'stats-courses',
          name: 'stats-courses',
          component: () => import(/* webpackChunkName: "userList" */ '../views/Statistics.vue')
      },
      {
        path: 'stats-licenses',
        name: 'stats-licenses',
        component: () => import(/* webpackChunkName: "userList" */ '../views/StatisticsLicenses.vue')
      },
      {
        path: 'stats-users',
        name: 'stats-users',
        component: () => import(/* webpackChunkName: "userList" */ '../views/StatisticsUsers.vue')
      }

    ]
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
