import Vue from "vue";
import {add, format, parseISO} from "date-fns";
import {de} from "date-fns/locale";

Vue.filter('formatLocaleDate', (date) => {
    return  date ? format(parseISO(date), 'PP', {locale: de}) : ''
})
Vue.filter('formatLocaleDateTime', (date) => {
    return  date ? (format(parseISO(date), 'dd.MM.yyyy HH:mm', {locale: de}) + ' Uhr') : ''
})
Vue.filter('effectiveDate', (days = 0, date) => {
    return  date? format(add(parseISO(date), { days }), 'PPPP', {locale: de}) :''
})
Vue.filter('uppercaseFirstLetter', (string) => {
    return string ? string.charAt(0).toUpperCase() : ''
})
Vue.filter('removeBracketsContent', (string) => {
    return string ? string.replace(/^\{.*\}/, '') : ''
})


